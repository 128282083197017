import React from "react";



const TimeZoneList = ({val=false, txt=false}) => {
  
    
  return(
    <>
    {(val && txt) &&
      <option value={val}>{txt}</option>
    }
    <option value="null" >{Intl.DateTimeFormat().resolvedOptions().timeZone.split("_").join(" ")}</option>
    <option value='6'>KGZ Asia/Bishkek </option>
    <option value='-5'>EST America/New York (Standard Time) </option>
    <option value='-4'>EDT America/New York (Daylight Savings Time) </option>
    <option value='-5'>EST America/Washington D.C. (Standard Time) </option>
    <option value='-4'>EDT America/Washington D.C. (Daylight Savings Time) </option>
    <option value='-5'>EST America/Hampton, VA (Standard Time) </option>
    <option value='-4'>EDT America/Hampton, VA (Daylight Savings Time) </option>
    <option value='-7'>MST America/Tempe </option>
    <option value='-5'>CST America/Bogotá (Standard Time) </option>
    <option value='0'>GMT Europe/London </option>
    <option value='1'>BST Europe/London (British Summer Time) </option>
    <option value='1'>CEST Europe/Berlin (Standard Time) </option>
    <option value='2'>CEST Europe/Berlin (Summer Time) </option>
    <option value='1'>CEST Europe/Vienna (Standard Time) </option>
    <option value='2'>CEST Europe/Vienna (Summer Time) </option>
    <option value='2'>EET Europe/Vilnius (Standard Time) </option>
    <option value='3'>EEST Europe/Vilnius (Summer Time) </option>
    <option value='2'>EET Europe/Volos (Standard Time) </option>
    <option value='3'>EEST Europe/Volos (Summer Time) </option>
    <option value='2'>EET Middle East/Abu Dis, Palestine (Standard Time) </option>
    <option value='3'>EEST Middle East/Abu Dis, Palestine (Summer Time) </option>
{/* 


    
  <option value="null" >{Intl.DateTimeFormat().resolvedOptions().timeZone.split("_").join(" ")}</option>
  <option value='10.5' >ACST (Australian Central Daylight Time)</option>
  <option value='9.5' >ACST (Australian Central Standard Time)</option>
  <option value='-3' >ADT (Atlantic Daylight Time)</option>
  <option value='0' >ADT </option>
  <option value='5' >ADT</option>
  <option value='10' >AEST</option>
  <option value='-8' >AKDT</option>
  <option value='-3' >AST</option>
  <option value='3' >AST</option>
  <option value='4' >AST</option>
  <option value='4.5' >AST</option>
  <option value='-3' >BST</option>
  <option value='1' >BST</option>
  <option value='6' >BST</option>
  <option value='-6' >CAST</option>
  <option value='6' >CAST</option>
  <option value='9.5' >CAST</option>
  <option value='-4' >CBST</option>
  <option value='-6' >CCST</option>
  <option value='-5' >CDT</option>
  <option value='2' >CEDT</option>
  <option value='11' >CPST</option>
  <option value='4' >CST</option>
  <option value='8' >CST</option>
  <option value='-1' >CVST</option>
  <option value='-12' >DST</option>
  <option value='3' >EAST</option>
  <option value='10' >EAST</option>
  <option value='-4' >EDT</option>
  <option value='3' >EEDT</option>
  <option value='-3' >ESAST</option>
  <option value='-5' >EST</option>
  <option value='2' >EST</option>
  <option value='3' >FDT</option>
  <option value='12' >FST</option>
  <option value='-3' >GDT</option>
  <option value='1' >GDT</option>
  <option value='3' >GDT</option>
  <option value='4' >GET</option>
  <option value='0' >GMT</option>
  <option value='0' >GST</option>
  <option value='-10' >HST</option>
  <option value='4.5' >IDT</option>
  <option value='5.5' >IST</option>
  <option value='3' >JDT</option>
  <option value='3' >JST</option>
  <option value='9' >JST</option>
  <option value='13' >KDT</option>
  <option value='3' >KST</option>
  <option value='9' >KST</option>
  <option value='2' >LST</option>
  <option value='-6' >MDT</option>
  <option value='-1' >MDT</option>
  <option value='1' >MDT</option>
  <option value='3' >MEDT</option>
  <option value='8' >MPST</option>
  <option value='3' >MSK</option>
  <option value='-3' >MST</option>
  <option value='4' >MST</option>
  <option value='6.5' >MST</option>
  <option value='12' >MST</option>
  <option value='8' >NAEST</option>
  <option value='8' >NAST</option>
  <option value='7' >NCAST</option>
  <option value='-2.5' >NDT</option>
  <option value='1' >NST</option>
  <option value='5.75' >NST</option>
  <option value='12' >NZST</option>
  <option value='-7' >PDT</option>
  <option value='5' >PKT</option>
  <option value='-4' >PSST</option>
  <option value='-8' >PST</option>
  <option value='-4' >PYT</option>
  <option value='2' >RDT</option>
  <option value='4' >SAMT</option>
  <option value='2' >SAST</option>
  <option value='7' >SAST</option>
  <option value='3' >SDT</option>
  <option value='-3' >SEST</option>
  <option value='5.5' >SLST</option>
  <option value='-5' >SPST</option>
  <option value='13' >SST</option>
  <option value='-4' >SWST</option>
  <option value='3' >TDT</option>
  <option value='8' >TST</option>
  <option value='10' >TST</option>
  <option value='13' >TST</option>
  <option value='-11' >U</option>
  <option value='-2' >U</option>
  <option value='12' >U</option>
  <option value='-5' >UEDT</option>
  <option value='-7' >UMST</option>
  <option value='8' >UST</option>
  <option value='0' >UTC</option>
  <option value='-4.5'>VST</option>
  <option value='11' >VST</option>
  <option value='5' >WAST</option>
  <option value='8' >WAST</option>
  <option value='1' >WCAST</option>
  <option value='2' >WEDT</option>
  <option value='10' >WPST</option>
  <option value='5' >YEKT</option>
  <option value='9' >YST</option> */}
</>)
};


export default TimeZoneList;
